/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
}

.custom-software-page {
  font-family: Arial, sans-serif;
}

/* Carousel container */
.carousel-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

/* Carousel slide */
.carousel-slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  animation: fadeEffect 10s ease-in-out infinite, zoomEffect 10s ease-in-out infinite;
}

/* Zoom effect */
@keyframes zoomEffect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Fade in-out effect */
@keyframes fadeEffect {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Text overlay container */
.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 40px;
  border-radius: 10px;
  text-align: center;
}

/* Carousel text */
.carousel-text {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  animation: fadeTextEffect 10s ease-in-out infinite;
}

/* Text fade effect */
@keyframes fadeTextEffect {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}


/* Mission Section Styling */
.mf-section {
  background-color: #f7f7f7;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* Container Styling - Centering Content */
.containermf{
  display: flex;
  justify-content: space-between;  /* Aligns content to the left and right */
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  flex-direction: row;  /* Ensures content is in a row (left and right) */
}

/* Mission Content */
.mf-content {
  width: 60%;  /* Adjust width to give more space to the text */
  max-width: 800px; /* Keeps the content from stretching too much */
  margin-right: 20px; /* Adds some space between the text and image */
}

.mf-heading {
  font-size: 36px;
  font-weight: bold;
  color: #2b3d47;
  margin-bottom: 20px;
}

.mf-description {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 40px;
}

.mf-image {
  width: 40%;  /* Adjust width for the image */
  max-width: 500px;
}

.mf-image img {
  width: 100%; /* Makes the image responsive */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.mf-image img:hover {
  transform: scale(1.05);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .containermf {
    flex-direction: column;
    text-align: center;
  }

  .mf-content {
    width: 100%;
    margin-bottom: 20px;
  }

  .mf-heading {
    font-size: 28px;
  }

  .mf-description {
    font-size: 16px;
  }

  .mf-image {
    width: 100%;
    margin-top: 20px;  /* Adds space between text and image in mobile view */
  }
}

.mf-button{
  text-align: center;
  
}

.rdm1{
  color:red;
  background-color: white;

}

.rdm1:hover{
  color:#218838;
}

 /* Style for the main container */
 .content-containers {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Main header style */
.main-headers {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 30px;
  transition: color 0.3s ease;
}

.main-headers:hover {
  color: #007bff; /* Blue hover effect */
}

/* Sub-header style */
.sub-headers {
  color: #ff4500; /* Red-orange color for sub-headers */
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 15px;
}

/* List styling */
.content-lists {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0 0 20px 0;
}

.content-lists li {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .main-headers {
    font-size: 2rem;
  }
  .sub-headers {
    font-size: 1.25rem;
  }
  .content-lists li {
    font-size: 1rem;
  }
}


.sect1-title1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.underlines1 {
  width: 150px;
  height: 4px;
  background-color: #e74c3c;
  margin: 0 auto 20px;
}

.feature-box2 {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  padding: 25px;
  text-align: center;
}

.feature-box2:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.feature-title2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.feature-text2 {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.fas2 {
  color: #e74c3c;
  transition: color 0.3s;
}

.feature-box2:hover .fas {
  color: #c0392b;
}

.scrollable-faq-section {
  background-color: #f8f9fa;
}

.faq-scroll-box {
  max-height: 300px; /* Set height for scrollable box */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
}

.faq-scroll-box::-webkit-scrollbar {
  width: 8px;
}

.faq-scroll-box::-webkit-scrollbar-thumb {
  background: #e74c3c;
  border-radius: 4px;
}

.faq-scroll-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.faq-item {
  transition: all 0.3s ease-in-out;
}

.faq-question {
  font-weight: 600;
}

.faq-answer {
  font-size: 0.9rem;
  color: #555;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* AppointmentSection.css */
.appointment {
  background-image: url('../images/ITCS6.jpeg'); /* Your background image URL */
  height: 50%;
  width: 100%;
  color: white; /* Text color to stand out on background */
  text-align: center;
  background-size: cover;
  background-position: center center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2px 0 4px 0;
}

.fact {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for better text contrast */
  border-radius: 12px;
  display: inline-block;
  max-width: 650px;
  width: 100%;
  margin-top: 20px;
}

.fact-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: #f4f4f4;
}

.fact-description {
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 40px;
  color: #ddd;
}

/* Button Styles */
.btn-appointment {
  display: inline-block;
  padding: 12px 35px;
  background-color: #848885; /* Green button color */
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.btn-appointment:hover {
  background-color: #218838; /* Darker green color on hover */
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25); /* Deepen shadow on hover */
}

.btn-appointment:focus {
  outline: none;
}

/* Added click effect */
.btn-appointment:active {
  background-color: #1e7e34; /* Darker green on click */
  transform: translateY(2px); /* Button pressed-down effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow on click */
}
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

.about-section {
  position: relative;
  width: 100%;
  height: 50vh; /* 50% of the viewport height */
  background: url('../images/about.avif') no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Section Styling */
.who-we-are {
  padding: 2rem 1rem;
  background-color: #f9f9f9;
  color: #333;
}

.section-title {
  font-size: 2rem;
  color: #007BFF;
  text-align: center;
  margin-bottom: 1rem;
}

.divider {
  width: 50px;
  height: 3px;
  background-color: #007BFF;
  margin: 0 auto 2rem auto;
  border: none;
}

/* Flexbox Layout for Content */
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: nowrap;
}

/* Text Styling */
.text {
  flex: 1;
  max-width: 60%;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

/* Image Container */
.image-container {
  position: relative;
  flex: 1;
  max-width: 35%;
}

/* Animated Image Styling */
.animated-image {
  width: 100%;
  height: auto;
  animation: shapeChange 5s infinite alternate;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Keyframes for Shape Animation */
@keyframes shapeChange {
  0% {
    clip-path: circle(50%);
  }
  25% {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  }
  50% {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  75% {
    clip-path: polygon(50% 0%, 85% 20%, 100% 50%, 85% 80%, 50% 100%, 15% 80%, 0% 50%, 15% 20%);
  }
  100% {
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    text-align: center;
  }

  .text {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .image-container {
    max-width: 100%;
  }
}

.content1 {
  display: flex;
  justify-content: space-between; /* Space between text and image */
  align-items: flex-start; /* Align items at the start */
  width: 80%; /* Container width */
  max-width: 1200px; /* Max width for large screens */
  margin: auto; /* Center the section */
  padding: 40px 0; /* Vertical padding */
}

.text1 {
  width: 60%; /* Text occupies 60% of the space */
}

/* Image container */
.image-container1 {
  width: 35%; /* Image occupies 35% of the space */
  display: flex; /* Ensure proper alignment */
  flex-direction: column; /* Stack images vertically */
  justify-content: space-between; /* Space between images */
}

/* Animated image styles */
.animated-image1,
.animated-image2,
.animated-image3,
.animated-image4 {
  width: 100%; /* Image takes full width of its container */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 200px; /* Add margin for spacing between images */
}

/* Remove margin from the last image */
.animated-image4 {
  margin-bottom: 0; /* No margin below the last image */
}

/* Animation */
.animated-image1 {
  animation: shapeChange1 5s infinite alternate; /* Animation for the first image */
}

.animated-image2 {
  animation: shapeChange2 6s infinite alternate; /* Animation for the second image */
}

.animated-image3 {
  animation: shapeChange3 7s infinite alternate; /* Animation for the third image */
}

.animated-image4 {
  animation: shapeChange4 8s infinite alternate; /* Animation for the fourth image */
}

/* Keyframes for different shape-changing animations */
@keyframes shapeChange1 {
  0% {
      clip-path: circle(50%);
  }
  50% {
      clip-path: polygon(50% 0%, 65% 20%, 100% 35%, 80% 100%, 50% 75%, 20% 100%, 0% 35%, 35% 20%);
  }
  100% {
      clip-path: polygon(0% 30%, 50% 0%, 100% 30%, 100% 70%, 50% 100%, 0% 70%);
  }
}

@keyframes shapeChange2 {
  0% {
      clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  }
  50% {
      clip-path: polygon(50% 0%, 100% 25%, 75% 100%, 25% 100%, 0% 25%); /* Star */
  }
  100% {
      clip-path: circle(50%);
  }
}

@keyframes shapeChange3 {
  0% {
      clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  }
  50% {
      clip-path: ellipse(70% 50%);
  }
  100% {
      clip-path: polygon(50% 0%, 100% 25%, 75% 100%, 25% 100%, 0% 25%); /* Star */
  }
}

@keyframes shapeChange4 {
  0% {
      clip-path: polygon(50% 0%, 100% 20%, 75% 50%, 100% 80%, 50% 100%, 0% 80%, 25% 50%, 0% 20%);
  }
  50% {
      clip-path: polygon(50% 0%, 75% 25%, 100% 50%, 75% 75%, 50% 100%, 25% 75%, 0% 50%, 25% 25%);
  }
  100% {
      clip-path: polygon(50% 0%, 100% 25%, 75% 100%, 25% 100%, 0% 25%); /* Star */
  }
}


/* Container with background image */
.background-image3 {
  background-image: url('../images/Serve.webp');
  background-size: cover; /* Cover the whole container */
  background-position: center; /* Center the background image */
  height: 50vh; /* Full height of the viewport */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  position: relative;
}

/* Styling for the content inside the image */
.content2 {
  text-align: center;
  color: white; /* White text for contrast */
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.491); /* Semi-transparent black background */
  border-radius: 10px;
}

/* Heading styling */
.content2 h1 {
  font-size: 3rem; /* Adjust size as needed */
  font-weight: 700;
}

/* Paragraph or additional content styling */
.content2 p {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #f8f9fa;
}


.team-container {
  text-align: center;
  padding: 50px 0;
  background-color: #f8f9fa;
}

.team-container h2 {
  font-size: 36px;
  margin-bottom: 40px;
  color: #333;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two cards per row */
  gap: 20px; /* Space between cards */
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}

.team-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.team-card:hover {
  transform: translateY(-10px);
}

.team-card img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.team-card h3 {
  margin: 15px 0;
  font-size: 22px;
  color: #333;
}

.team-card .title {
  font-size: 16px;
  color: #888;
  margin-bottom: 10px;
}

.team-card p {
  font-size: 14px;
  color: #666;
}

/* Responsive layout */
@media (max-width: 768px) {
  .team-grid {
      grid-template-columns: 1fr; /* Stack cards on smaller screens */
  }
}


/* General Styles for Section */
.section {
  padding: 50px 0;
  background-color: #f2f2f2;
}

.cont {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* Card Styles */
.cd {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
  transform: scale(1);
}

.cd:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Perspective for 3D effect */
.cont {
  perspective: 1000px;
}

/* Card Content */
.cd-body {
  text-align: center;
}

.cd-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.cd-text {
  font-size: 1rem;
  color: #777;
  margin-bottom: 15px;
}

.readmore {
  font-size: 1rem;
  color: #e74c3c;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
}

.readmore:hover {
  color: #c0392b;
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .col-md-4 {
    flex: 1 1 45%; /* Adjust card size on small screens */
  }
}

@media (max-width: 576px) {
  .col-md-4 {
    flex: 1 1 100%; /* Stack cards on extra small screens */
  }
}

/* AppointmentSection.css */
.Aappointment {
  background-image: url('../images/abouta.jpg'); /* Your background image URL */
  height: 50%;
  width: 100%;
  color: white; /* Text color to stand out on background */
  text-align: center;
  background-size: cover;
  background-position: center center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2px 0 4px 0;
}

.fact {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for better text contrast */
  border-radius: 12px;
  display: inline-block;
  max-width: 650px;
  width: 100%;
  margin-top: 20px;
}

.fact-heading {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: #f4f4f4;
}

.fact-description {
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 40px;
  color: #ddd;
}

/* Button Styles */
.Abtn-appointment {
  display: inline-block;
  padding: 12px 35px;
  background-color: #1564b3; /* Green button color */
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.Abtn-appointment:hover {
  background-color: #2eca50; /* Darker green color on hover */
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25); /* Deepen shadow on hover */
}

.Abtn-appointment:focus {
  outline: none;
}

/* Added click effect */
.Abtn-appointment:active {
  background-color: #1e7e34; /* Darker green on click */
  transform: translateY(2px); /* Button pressed-down effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow on click */
}


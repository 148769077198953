/* General styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
}

/* Navbar Styles */
.navbar {
    background-color: rgb(145, 131, 131);
    padding: 10px 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    position: fixed;
    width: 100%;
    transition: background-color 1.5s ease;
}

#myNavbar {
    background-color: #38404260 !important;
}

/* Navbar links */
#myNavbar .navbar-nav .nav-link {
    color: rgb(231, 135, 135);
}

/* Navbar scroll effect */
.navbar.scrolled {
    background-color: #c19a6b;
}

.navbar-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.nav-item {
    position: relative;
    margin: 0 15px;
}

/* Link styling */
.nav-link,
.dropdown-toggle {
    color: rgb(108, 13, 13);
    text-decoration: none;
    padding: 10px 20px;
    transition: background-color 0.3s, color 0.3s;
}

.navbar.scrolled .nav-link,
.navbar.scrolled .dropdown-toggle {
    color: white;
}

.nav-link:hover,
.nav-link:focus,
.dropdown-toggle:hover,
.dropdown-toggle:focus {
    background-color: greenyellow;
    color: white;
    border-radius: 50px; 
}

/* Dropdown menu */
.dropdown-content,
.sub-dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 200px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.dropdown:hover .dropdown-content,
.sub-dropdown:hover .sub-dropdown-content {
    display: block;
}

.dropdown-content li a,
.sub-dropdown-content li a {
    padding: 12px 16px;
    text-decoration: none;
    color: rgb(231, 135, 135);
    transition: background-color 0.3s;
}

.dropdown-content li a:hover,
.sub-dropdown-content li a:hover {
    background-color: #f4a261;
    color: white;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .navbar-nav {
        flex-direction: column;
        width: 100%;
        display: none;
    }

    .navbar.active .navbar-nav {
        display: flex;
    }

    .nav-item {
        margin: 5px 0;
        border-radius: 25px; 
    }

    .navbar-toggle {
        display: block;
    }
}

.navbar-toggler {
    display: block;
}

.navbar-toggler-icon {
    background-color: #fff;
}

/* Centered logo text styling */
/* Centered logo container styling */
.center {
    display: flex;
    align-items: center;
    padding-left: 10px; /* Adjust spacing as needed */
    background-color: lightdark;
}

/* Styling for the logo */
.logo {
    width: 150px; /* Adjust logo size */
    height: auto;
    filter: brightness(0) sepia(1) saturate(1000%) hue-rotate(0deg); /* Initial color */
    animation: colorCycle 5s infinite; /* Apply the animation */
    transition: filter 0.3s ease;
}

/* Keyframes for continuous color change */
@keyframes colorCycle {
    0% {
        filter: brightness(1) sepia(0.4) saturate(200%) hue-rotate(0deg); /* Light red with a hint of warmth */
    }
    25% {
        filter: brightness(1.2) sepia(0.5) saturate(300%) hue-rotate(10deg); /* Subtle increase in warmth */
    }
    50% {
        filter: brightness(1.5) sepia(0.8) saturate(400%) hue-rotate(15deg); /* Rich red tone with more depth */
    }
    75% {
        filter: brightness(1.8) sepia(0.6) saturate(500%) hue-rotate(10deg); /* Vivid red with a slight fade */
    }
    100% {
        filter: brightness(2) sepia(0.4) saturate(200%) hue-rotate(0deg); /* Return to the light red gradient */
    }
}


/* Optional hover effect */
.logo:hover {
    filter: brightness(2) sepia(1) saturate(1200%) hue-rotate(45deg); /* Special hover highlight */
    animation-play-state: paused; /* Pause the animation on hover */
}



.form-control{
    color: transparent;
}

.btn{
    color: rgb(254, 12, 12);
    padding-right: 55px;
    margin: 6px;
    border-radius: 25px; 
    text-align: center;
    padding-top: 15px;
}

.btn:hover{
    background-color: green;
}


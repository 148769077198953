/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
  }
  
  /* Carousel container */
  .carousl-container5 {
    position: relative;
    width: 100%;
    height: 500px; /* Adjusted height for the carousel */
    overflow: hidden;
  }
  
  /* Carousel slide */
  .carousel-slide6 {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    animation: fadeEffect 10s ease-in-out infinite, zoomEffect 10s ease-in-out infinite;
  }
  
  /* Zoom effect */
  @keyframes zoomEffect {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Fade in-out effect */
  @keyframes fadeEffect {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  /* Text overlay container */
  .txt-overlay5 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    padding: 20px 40px;
    border-radius: 10px;
    text-align: center;
  }
  
  /* Carousel text */
  .carousel-txt5 {
    font-size: 24px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
    animation: fadeTextEffect 10s ease-in-out infinite;
  }
  
  /* Text fade effect */
  @keyframes fadeTextEffect {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  /* Mission Section Styling */
  .vision6-section {
    background-color: #f7f7f7;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  .containerr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    flex-direction: row-reverse; /* Reverses the order of image and content */
  }
  
  .vision6-content {
    width: 60%;
    max-width: 800px;
    margin-left: 20px; /* Adjust space between image and content */
  }
  
  .vision6-heading {
    font-size: 36px;
    font-weight: bold;
    color: #2b3d47;
    margin-bottom: 20px;
    padding-left: 50px;
  }
  
  .vision6-description {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px; /* Adjust this to set the spacing between the description and the button */
  }
  
  .readmore6 {
    color: red;
    font-weight: 600;
    padding-left: 80px;
    margin-top: 20px;  /* Adds space between the description and the "read more" button */
  }

  .readmore6:hover {
    color: green;
     /* Adds space between the description and the "read more" button */
  }
  
  .vision6-image {
    width: 40%;
    max-width: 500px;
  }
  
  .vision6-image img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .vision6-image img:hover {
    transform: scale(1.05);
  }
  
  @media screen and (max-width: 768px) {
    .containerr {
      flex-direction: column;
      text-align: center;
    }
  
    .vision6-content {
      width: 100%;
      margin-bottom: 20px;
      padding-top: 5px;
    }
  
    .vision6-heading {
      font-size: 20px;
      padding-top: 5px;
    }
  
    .vision6-description {
      font-size: 16px;
    }
  
    .vision6-image {
      width: 100%;
      margin-top: 20px;
    }
  }
  
  
   /* Background Image */
.custom-background-image3 {
  background-image: url('../images/CSC5.webp');
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Content Styling */
.custom-content2 {
  text-align: center;
  color: white;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.491);
  border-radius: 10px;
}

.custom-content2 h1 {
  font-size: 3rem;
  font-weight: 700;
}

.custom-content2 p {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #f8f9fa;
}

/* General Section */
.custom-section {
  padding: 50px 0;
  background-color: #f2f2f2;
}

.custom-cont {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
}

.custom-col-md-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.custom-mt-3 {
  margin-top: 1rem;
}

.custom-cd {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 1rem;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.custom-cd:hover {
  transform: translateY(-10px);
}

.custom-cd-title {
  font-size: 22px;
  color: #333;
}

.custom-cd-text {
  font-size: 14px;
  color: #666;
}

.custom-readmore {
  font-size: 14px;
  color: #e74c3c;
  text-decoration: none;
}

.custom-underline {
  width: 170px;
  height: 4px;
  background-color: #e74c3c;
  margin: 0 auto;
}


/* General Section */
.custom-section {
  padding: 50px 0;
  background-color: #f2f2f2;
}

.custom-cont {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
}

.custom-col-md-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.custom-mt-3 {
  margin-top: 1rem;
}

.custom-cd {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 1rem;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.custom-cd:hover {
  transform: translateY(-10px);
}

.custom-cd-title {
  font-size: 22px;
  color: #333;
}

.custom-cd-text {
  font-size: 14px;
  color: #666;
}

.custom-readmore {
  font-size: 14px;
  color: #e74c3c;
  text-decoration: none;
}

.custom-underline {
  width: 170px;
  height: 4px;
  background-color: #e74c3c;
  margin: 0 auto;
}

  /* Style for the main container */
.content-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Main header style */
.main-header {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 30px;
  transition: color 0.3s ease;
}

.main-header:hover {
  color: #007bff; /* Blue hover effect */
}

/* Sub-header style */
.sub-header {
  color: #ff4500; /* Red-orange color for sub-headers */
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 15px;
}

/* List styling */
.content-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0 0 20px 0;
}

.content-list li {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .main-header {
    font-size: 2rem;
  }
  .sub-header {
    font-size: 1.25rem;
  }
  .content-list li {
    font-size: 1rem;
  }
}


  /* Style for the main container */
.content-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

 /* Style for the main container */
.content-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Main header style */
.main-header {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 30px;
  transition: color 0.3s ease;
}

.main-header:hover {
  color: #007bff; /* Blue hover effect */
}

/* Sub-header style */
.sub-header {
  color: #ff4500; /* Red-orange color for sub-headers */
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 15px;
}

/* List styling */
.content-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0 0 20px 0;
}

.content-list li {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .main-header {
    font-size: 2rem;
  }
  .sub-header {
    font-size: 1.25rem;
  }
  .content-list li {
    font-size: 1rem;
  }
}


/* AppointmentSection.css */
.appointmentcs {
  background-image: url('../images/CSC6.webp'); /* Your background image URL */
  height: 50%;
  width: 100%;
  color: white; /* Text color to stand out on background */
  text-align: center;
  background-size: cover;
  background-position: center center;
}

.containercs {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2px 0 4px 0;
}

.factcs {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for better text contrast */
  border-radius: 12px;
  display: inline-block;
  max-width: 650px;
  width: 100%;
  margin-top: 20px;
}

.fact-headingcs {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: #f4f4f4;
}

.fact-descriptioncs {
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 40px;
  color: #ddd;
}

/* Button Styles */
.btn-appointmentcs {
  display: inline-block;
  padding: 12px 35px;
  background-color: lightgrey; /* Green button color */
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.btn-appointmentcs:hover {
  background-color: #0cf23e; /* Darker green color on hover */
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25); /* Deepen shadow on hover */
}

.btn-appointmentcs:focus {
  outline: none;
}

/* Added click effect */
.btn-appointmentcs:active {
  background-color: #1e7e34; /* Darker green on click */
  transform: translateY(2px); /* Button pressed-down effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow on click */
}
/* Main container for the home section */
.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  color: white;
}

.home-content-section {
  position: relative;
  z-index: 2;
}

.scrollable-background {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 300%;
  animation: scroll-background 9s ease-in-out infinite;
}

.scrollable-background-image {
  flex: 1;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  animation: zoom-in 9s ease-in-out infinite;
  transform: scale(1);
}

.scrollable-background-image:nth-child(1) {
  background-image: url('../../assets/images/Background1.jpg');
}

.scrollable-background-image:nth-child(2) {
  background-image: url('../../assets/images/Background2.jpg');
}

.scrollable-background-image:nth-child(3) {
  background-image: url('../../assets/images/Background3.jpg');
}

@keyframes scroll-background {
  0%, 33.33% { transform: translateX(0); }
  44.44%, 77.77% { transform: translateX(-100vw); }
  88.88%, 100% { transform: translateX(-200vw); }
}

@keyframes zoom-in {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}

.home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.home-content {
  max-width: 800px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.home-content h2 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.home-content p {
  font-size: 1.2rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .home-content h2 {
    font-size: 1.5rem;
  }
  .home-content p {
    font-size: 1rem;
  }
}

/* Mission Section Styling */
.mission-section {
  background-color: #f7f7f7;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.container1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  flex-direction: row;
}

.mission-content {
  width: 60%;
  max-width: 800px;
  margin-right: 20px;
}

.mission-heading {
  font-size: 36px;
  font-weight: bold;
  color: #2b3d47;
  margin-bottom: 20px;
}

.mission-description {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 40px;
}

.mission-image {
  width: 40%;
  max-width: 500px;
}

.mission-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.mission-image img:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .container1 {
    flex-direction: column;
    text-align: center;
  }

  .mission-content {
    width: 100%;
    margin-bottom: 20px;
  }

  .mission-heading {
    font-size: 28px;
  }

  .mission-description {
    font-size: 16px;
  }

  .mission-image {
    width: 100%;
    margin-top: 20px;
  }
}

/* Section Styling */
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.section-title {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  color: #2b3d47;
  margin-bottom: 20px;
  line-height: 1.4;
  align-self: flex-start;
  transition: transform 0.3s ease;
  margin-top: 0;
}

.section-title:hover {
  transform: scale(1.1);
}

.underline {
  width: 100px;
  height: 4px;
  background-color: red;
  margin: 10px auto;
}

.text-red {
  color: red !important;
}

.readmore {
  color: red;
  font-weight: 600;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .section {
    padding: 20px;
  }

  .section-title {
    font-size: 24px;
  }

  .underline {
    width: 80px;
  }
}

/* Card Styling */
.card-title {
  font-size: 20px;
  font-weight: 600;
  transition: transform 0.3s ease;
}

.card-title:hover {
  transform: scale(1.1);
}

.card:hover .card-img-top {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.card:hover .card-body {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

/* Purpose Section Styling */
.purpose h3 {
  text-align: center;
  font-size: 1.5rem;
  color: #9e2a2f;
  font-family: 'Arial', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: linear-gradient(45deg, #9e2a2f, #b85c46, #cb8f43);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2), 0 0 25px rgba(156, 42, 47, 0.5);
  padding: 10px 0;
}

/* Testimonial Card Styling */
.testimonial-card {
  display: flex;
  padding: 30px;
  background-color: #f5f7fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 0 auto;
}

.testimonial-content {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.profile-image:hover {
  width: 200px;
  height: 200px;
  transform: scale(1.1);
}

.quote-section {
  width: 100%;
}

.quote {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #333;
  margin: 20px 0;
}

.quote-icon {
  font-size: 2rem;
  color: #333;
  font-weight: bold;
}

.link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.link {
  color: #242327;
  text-decoration: none;
  font-weight: bold;
  color: #b97215;
  padding-left: 450px;
  margin-bottom: 0%;
}

.link:hover {
  text-decoration: underline;
  color: #1e7e34;
}

.author-info {
  margin-top: 10px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Teko', sans-serif;
}

p {
  font-family: 'Poppins', sans-serif !important;
}

.purpose p{
  text-align:center;
}


.mission-wrapper {
  padding: 40px 20px;
  background-color: #f9f9f9; /* Optional background */
}

.mission-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensures responsiveness */
  gap: 20px;
}

.mission-img-wrapper img {
  width: 100%;
  max-width: 400px; /* Control image size */
  height: auto;
  border-radius: 8px; /* Rounded corners for a modern look */
}

.mission-text-wrapper {
  max-width: 600px; /* Limit width for better readability */
}

.mission-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333; /* Adjust color as per your theme */
}

.mission-paragraph {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555; /* Adjust color as per your theme */
}

.mission-action-btn {
  display: inline-block;
  padding: 10px 20px;
  color: red;
  font-weight: 600;
              /* Primary button color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

}

.mission-action-btn:hover {
    color: #0dea2e; /* Darker shade on hover */
}

.case-studies-section {
  padding: 40px;
  background-color: #f9f9f9;
  text-align: center;
}

.case-studies-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-items: center;
}

.case-study-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.case-study-card h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.case-study-card table {
  width: 100%;
  border-collapse: collapse;
}

.case-study-card table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.case-study-card table td strong {
  font-weight: bold;
}

@media (max-width: 768px) {
  .case-studies-container {
    grid-template-columns: 1fr;
  }

  .case-study-card {
    width: 90%;
  }
}
.conclusion{
  font-size: 1.2rem;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 5px;
  margin-right: 5px;
}
/* WallOfImages.css */
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between columns */
}

.gallery-column {
  width: 48%;  /* Adjust this to make space for 2 items per row */
  margin-bottom: 20px; /* Space between rows */
}

.gallery-image-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-image-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.gallery-image-card img {
  width: 100%; /* Make image fill container width */
  height: 250px; /* Fixed height for all images */
  object-fit: cover; /* Ensures the image fills the area without distorting its aspect ratio */
  border-radius: 10px;
  transition: opacity 0.3s ease;
}

.gallery-image-card:hover img {
  opacity: 0.8;
}

.image-caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 10px;
}

.gallery-image-card:hover .image-caption {
  opacity: 1;
}

.image-alt {
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
}

/* Hide alt text on hover */
.gallery-image-card:hover .image-alt {
  visibility: hidden;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .gallery-column {
    width: 100%; /* Stacks the images in one column on small screens */
  }

  .gallery-image-card img {
    height: 200px; /* Fixed height on small screens */
  }

  .image-caption h5 {
    font-size: 1.2rem;
  }
}

/* AppointmentSection.css */
.appointment {
  background-color: #f5f5f5;  /* Light gray background */
  padding: 50px 0;
}

.fact {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.fact-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.fact-description {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .fact-heading {
    font-size: 2rem;
  }

  .fact-description {
    font-size: 1rem;
  }
}
/* Image Styles */
.mission-image {
  width: 50%; /* Set image width to 50% */
  height: 50%; /* Set image height to 50% */
  margin: 0 auto;
}

.mission-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image covers the given area */
  border-radius: 10px; /* Rounded corners for the image */
}

@media (max-width: 768px) {
  .fact-heading {
    font-size: 2.2rem;
  }

  .fact-description {
    font-size: 1rem;
  }

  .btn-appointment {
    font-size: 1.2rem;
    padding: 10px 25px;
  }

  .mission-image {
    width: 100%; /* Make image full-width on smaller screens */
    height: auto; /* Keep the aspect ratio on smaller screens */
  }
}

/* AppointmentSection.css */
.appointmentn {
  background-image: url('../images/Sn.webp'); /* Your background image URL */
  height: 50%;
  width: 100%;
  color: white; /* Text color to stand out on background */
  text-align: center;
  background-size: cover;
  background-position: center center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2px 0 4px 0;
}

.fact {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for better text contrast */
  border-radius: 12px;
  display: inline-block;
  max-width: 650px;
  width: 100%;
  margin-top: 20px;
}

.fact-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: #f4f4f4;
}

.fact-description {
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 40px;
  color: #ddd;
}

/* Button Styles */
.btn-appointment {
  display: inline-block;
  padding: 12px 35px;
  background-color: #848885; /* Green button color */
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.btn-appointment:hover {
  background-color: #218838; /* Darker green color on hover */
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25); /* Deepen shadow on hover */
}

.btn-appointment:focus {
  outline: none;
}

/* Added click effect */
.btn-appointment:active {
  background-color: #1e7e34; /* Darker green on click */
  transform: translateY(2px); /* Button pressed-down effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow on click */
}



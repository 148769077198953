/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
}

.custom-software-page {
  font-family: Arial, sans-serif;
}

/* Carousel container */
.carousel-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

/* Carousel slide */
.carousel-slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  animation: fadeEffect 10s ease-in-out infinite, zoomEffect 10s ease-in-out infinite;
}

/* Zoom effect */
@keyframes zoomEffect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Fade in-out effect */
@keyframes fadeEffect {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Text overlay container */
.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 40px;
  border-radius: 10px;
  text-align: center;
}

/* Carousel text */
.carousel-text {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  animation: fadeTextEffect 10s ease-in-out infinite;
}

/* Text fade effect */
@keyframes fadeTextEffect {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Layout for left and right columns */
.container-fluid {
  padding: 0;
}

.full-height {
  height: 100vh;
}

.description-container {
  background-color: #f8f9fa;
  height: 50%;
}

.description {
  font-size: 1rem;
  color: #333;
  line-height: 1;
}

.text-center {
  text-align: center;
}

/* Image container with professional effects */
.image-container {
  position: relative;
  height: 50%;
  width: 50%;
  overflow: hidden;
}

.dynamic-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect */
.dynamic-image:hover {
  transform: scale(1.1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
}
/* Section Styling */
.sms {
  padding: 2rem 1rem;
  background-color: #f9f9f9;
  color: #333;
}

.section-titles {
  font-size: 2rem;
  color: #007BFF;
  text-align: center;
  margin-bottom: 1rem;
}

.divider1s {
  width: 50px;
  height: 3px;
  background-color: red;
  margin: 0 auto 2rem auto;
  border: none;
}

/* Flexbox Layout for Content */
.contxts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: nowrap;
}

/* Image Container (Left) */
.pic-containers {
  position: relative;
  flex: 1;
  max-width: 40%;
}

/* Animated Image Styling */
.animated-pics {
  width: 100%;
  height: auto;
  animation: shapeChange 6s infinite alternate;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Keyframes for Shape Animation */
@keyframes shapeChange {
    0% {
      clip-path: circle(50%);
    }
    25% {
      clip-path: ellipse(70% 50% at 50% 50%);
    }
    50% {
      clip-path: polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
    }
    75% {
      clip-path: polygon(50% 0%, 85% 20%, 100% 50%, 85% 80%, 50% 100%, 15% 80%, 0% 50%, 15% 20%);
    }
    100% {
      clip-path: polygon(30% 0%, 100% 20%, 70% 100%, 0% 80%);
    }
  
}

/* Text Styling (Right) */
.textts {
  flex: 1;
  max-width: 60%;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.textts p {
  margin-bottom: 1rem;
}

/* Action Button */
.action-button-link {
  text-decoration: none; /* Remove underline from the link */
}

.action-button {
  align-self: center;
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contxts {
    flex-direction: column;
    text-align: center;
  }

  .textts {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .pic-containers {
    max-width: 100%;
  }
}
.rdm {
  color: red;
}

.rdm:hover {
  color: green;
}

.why-txt {
  text-align: center; /* Center-align the text */
  padding-right: 20px;
  padding-left: 40px;
}

.why-txt h3 {
  font-size: 2rem; /* Larger heading font size */
  color: #2c3e50; /* Darker heading color */
  margin-bottom: 10px;
}

.why-txt hr {
  border: none;
  height: 3px;
  background-color: #3498db; /* Blue accent color */
  width: 80%; /* Centered divider */
  margin: 15px auto;
}

.why-txt ul {
  list-style: none; /* Remove default bullets */
  padding: 0;
}


.why-txt li {
  margin-bottom: 15px; /* Spacing between items */
  font-size: 1.1rem; /* Slightly larger text */
  color: #555; /* Text color */
  text-align: left; /* Align list items to the left */
  line-height: 1.6; /* Improve readability */
}

.why-txt li strong {
  color: #e74c3c; /* Highlighted text color */
}

@media (max-width: 768px) {
  .custom-software-container {
    padding: 15px;
  }

  .why-txt h3 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }
}


/* Card Styling */
.card {
  border: 1px solid #ddd;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.card-text {
  font-size: 14px;
  color: #666;
}

.card-img-top {
  max-height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card:hover .card-img-top {
  transform: scale(1.05);
}

.sect1-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.underlines {
  width: 150px;
  height: 4px;
  background-color: #e74c3c;
  margin: 0 auto 20px;
}

.feature-box1 {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  padding: 25px;
  text-align: center;
}

.feature-box1:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.feature-title1 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.feature-text1 {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.fas1 {
  color: #e74c3c;
  transition: color 0.3s;
}

.feature-box1:hover .fas {
  color: #c0392b;
}


/* AppointmentSection.css */
.appointmentd {
  background-image: url('../images/SD6.jpg'); /* Your background image URL */
  height: 50%;
  width: 100%;
  color: white; /* Text color to stand out on background */
  text-align: center;
  background-size: cover;
  background-position: center center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2px 0 4px 0;
  
}

.fact {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for better text contrast */
  border-radius: 12px;
  display: inline-block;
  max-width: 650px;
  width: 100%;
  margin-top: 20px;
}

.fact-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: #f4f4f4;
}

.fact-description {
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 40px;
  color: #ddd;
}

/* Button Styles */
.btn-appointment {
  display: inline-block;
  padding: 12px 35px;
  background-color: #848885; /* Green button color */
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.btn-appointment:hover {
  background-color: #218838; /* Darker green color on hover */
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25); /* Deepen shadow on hover */
}

.btn-appointment:focus {
  outline: none;
}

/* Added click effect */
.btn-appointment:active {
  background-color: #1e7e34; /* Darker green on click */
  transform: translateY(2px); /* Button pressed-down effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow on click */
}
/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
  }
  
  /* Carousel container */
  .carousl-container1 {
    position: relative;
    width: 100%;
    height: 500px; /* Adjusted height for the carousel */
    overflow: hidden;
  }
  
  /* Carousel slide */
  .carousel-slide2 {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    animation: fadeEffect 10s ease-in-out infinite, zoomEffect 10s ease-in-out infinite;
  }
  
  /* Zoom effect */
  @keyframes zoomEffect {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Fade in-out effect */
  @keyframes fadeEffect {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  /* Text overlay container */
  .txt-overlay1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    padding: 20px 40px;
    border-radius: 10px;
    text-align: center;
  }
  
  /* Carousel text */
  .carousel-txt1 {
    font-size: 24px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
    animation: fadeTextEffect 10s ease-in-out infinite;
  }
  
  /* Text fade effect */
  @keyframes fadeTextEffect {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  .readmore2 {
    color: red;
    font-weight: 600;
    text-decoration: underline;
  }

  .readmore2:hover{
    color: #1e7e34;
  }

  .quote-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

 /* Section Styling */
.bench-staffing {
  padding: 2rem 1rem;
  background-color: #f9f9f9;
  color: #333;
}

.section-titl {
  font-size: 2rem;
  color: #007BFF;
  text-align: center;
  margin-bottom: 1rem;
}

.divider2 {
  width: 50px;
  height: 3px;
  background-color: red;
  margin: 0 auto 2rem auto;
  border: none;
}

/* Flexbox Layout for Content */
.contxt1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: nowrap;
}

/* Image Container */
.pic-container1 {
  position: relative;
  flex: 1;
  max-width: 40%;  /* Image takes up 40% of the space */
}

/* Animated Image Styling */
.animated-pic {
  width: 100%;
  height: auto;
  animation: shapeChange 5s infinite alternate;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Keyframes for Shape Animation */
@keyframes shapeChange {
  0% {
    clip-path: circle(50%);
  }
  25% {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  }
  50% {
    clip-path: polygon(50% 0%, 75% 25%, 100% 50%, 75% 75%, 50% 100%, 25% 75%, 0% 50%, 25% 25%);
  }
  75% {
    clip-path: polygon(50% 0%, 85% 20%, 100% 50%, 85% 80%, 50% 100%, 15% 80%, 0% 50%, 15% 20%);
  }
  100% {
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  }
}

/* Text Styling */
.textt1 {
  flex: 1;
  max-width: 60%;  /* Text takes up 60% of the space */
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contxt1 {
    flex-direction: column; /* Stack image and text vertically on small screens */
    text-align: center;
  }

  .textt1 {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .pic-container1 {
    max-width: 100%;
  }
}

.container {
  margin-top: 50px;
}

.quote-card {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  position: relative; /* This ensures the image can float above the content */
}

.quote-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.quote-img-wrapper {
  width: 100%; /* Ensure it spans the full width */
  height: 45%; /* Set the height of the image to 30% of the quote card's height */
  position: absolute; /* Position the image absolutely inside the card */
  top: 0; /* Align it at the top of the card */
  left: 0;
  overflow: hidden;
  z-index: 10;
}

.quote-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the whole space */
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.quote-content {
  padding: 20px;
  width: 100%;
  margin-top: 25%; /* Push the content down to avoid overlap with the image */
}

.quote-header {
  font-size: 1.75rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 15px;
}

.quote-description {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}

.btnn {
  padding: 12px 25px;
  font-size: 1rem;
  color: red;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btnn:hover {
  color: green;
}

/* Full-width Quote Card */
.col-12 {
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.shadow {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.mb-4 {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .quote-card {
    width: 100%;
    margin-bottom: 20px;
  }
}

/* Center header and make it attractive */
.header {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.header:hover {
  color: #007bff; /* Change to blue when hovered */
}

/* Style the ordered list to have dot numbering */
.paragraph-list {
  list-style-type: decimal;
  padding-left: 20px;
  margin: 0;
}

/* Style for paragraph headings */
.paragraph-heading {
  color: red; /* Red color for headings */
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 8px;
}

/* Style for the paragraphs */
.paragraph-list p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Ensure good layout for the container */
.bs1 {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .header {
    font-size: 2rem;
  }
  .paragraph-heading {
    font-size: 1.1rem;
  }
  .paragraph-list p {
    font-size: 1rem;
  }
}



/* AppointmentSection.css */
.appointment {
  background-image: url('../images/BS9.webp'); /* Your background image URL */
  height: 50%;
  width: 100%;
  color: white; /* Text color to stand out on background */
  text-align: center;
  background-size: cover;
  background-position: center center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2px 0 4px 0;
}

.fact {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for better text contrast */
  border-radius: 12px;
  display: inline-block;
  max-width: 650px;
  width: 100%;
  margin-top: 20px;
}

.fact-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: #f4f4f4;
}

.fact-description {
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 40px;
  color: #ddd;
}

/* Button Styles */
.btn-appointment {
  display: inline-block;
  padding: 12px 35px;
  background-color: #848885; /* Green button color */
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.btn-appointment:hover {
  background-color: #218838; /* Darker green color on hover */
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25); /* Deepen shadow on hover */
}

.btn-appointment:focus {
  outline: none;
}

/* Added click effect */
.btn-appointment:active {
  background-color: #1e7e34; /* Darker green on click */
  transform: translateY(2px); /* Button pressed-down effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow on click */
}
/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
  }
  
  /* Carousel container */
  .carousl-container2 {
    position: relative;
    width: 100%;
    height: 500px; /* Adjusted height for the carousel */
    overflow: hidden;
  }
  
  /* Carousel slide */
  .carousel-slide3 {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    animation: fadeEffect 10s ease-in-out infinite, zoomEffect 10s ease-in-out infinite;
  }
  
  /* Zoom effect */
  @keyframes zoomEffect {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Fade in-out effect */
  @keyframes fadeEffect {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  /* Text overlay container */
  .txt-overlay2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    padding: 20px 40px;
    border-radius: 10px;
    text-align: center;
  }
  
  /* Carousel text */
  .carousel-txt2 {
    font-size: 24px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
    animation: fadeTextEffect 10s ease-in-out infinite;
  }
  
  /* Text fade effect */
  @keyframes fadeTextEffect {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  /* Mission Section Styling */
  .vision3-section {
    background-color: #f7f7f7;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  .containerp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    flex-direction: row;
  }
  
  .vision3-content {
    width: 60%;
    max-width: 800px;
    margin-right: 20px;
  }
  
  .vision3-heading {
    font-size: 36px;
    font-weight: bold;
    color: #2b3d47;
    margin-bottom: 10px;
  }
  
  .vision3-description {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  
  .vision3-image {
    width: 40%;
    max-width: 500px;
  }
  
  .vision3-image img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .vision3-image img:hover {
    transform: scale(1.05);
  }
  
  /* Mobile Adjustments */
  @media screen and (max-width: 762px) {
    .containerp {
      flex-direction: column;
      text-align: center;
    }
  
    .vision3-content {
      width: 100%;
      margin-bottom: 20px;
      padding-top: 5px;
    }
  
    .vision3-heading {
      font-size: 20px;
      padding-top: 5px;
    }
  
    .vision3-description {
      font-size: 16px;
    }
  
    .vision3-image {
      width: 100%;
      margin-top: 20px;
    }
  
    /* Adjust carousel container height for smaller screens */
    .carousl-container2 {
      height: 300px;
    }
  
    /* Adjust text overlay size */
    .txt-overlay2 {
      padding: 10px 20px;
    }
  
    .carousel-txt2 {
      font-size: 18px;
    }
  }
  .readmore3 {
    color: red;
    font-weight: 600;
    text-decoration: underline;
  }

  .readmore3:hover{
    color:green;
  }

  /* Background Image */
.custom-bg-image3 {
  background-image: url('../images/FC5.webp');
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Content Styling */
.custom-content2 {
  text-align: center;
  color: white;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.491);
  border-radius: 10px;
}

.custom-content2 h1 {
  font-size: 3rem;
  font-weight: 700;
}

.custom-content2 p {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #f8f9fa;
}

/* General Section */
.custom-section {
  padding: 50px 0;
  background-color: #f2f2f2;
}

.custom-cont {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
}

.custom-col-md-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.custom-mt-3 {
  margin-top: 1rem;
}

.custom-cd {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 1rem;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.custom-cd:hover {
  transform: translateY(-10px);
}

.custom-cd-title {
  font-size: 22px;
  color: #333;
}

.custom-cd-text {
  font-size: 14px;
  color: #666;
}

.custom-readmore {
  font-size: 14px;
  color: #e74c3c;
  text-decoration: none;
}

.custom-underline {
  width: 170px;
  height: 4px;
  background-color: #e74c3c;
  margin: 0 auto;
}

/* Header styling */
.choose-header {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.choose-header:hover {
  color: #007bff; /* Change to blue when hovered */
}

/* Style the ordered list to have decimal numbering */
.choose-list {
  list-style-type: decimal;
  padding-left: 20px;
  margin: 0;
}

/* Style for section headings */
.choose-heading {
  color: #007bff; /* Blue color for headings */
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 8px;
}

/* Style for the paragraphs */
.choose-list p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Ensure good layout for the container */
.choose-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .choose-header {
    font-size: 2rem;
  }
  .choose-heading {
    font-size: 1.1rem;
  }
  .choose-list p {
    font-size: 1rem;
  }
}

/* Header styling */
.apart-header {
  text-align: center;
  font-size: 2.5rem;
  color: #222;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: color 0.3s ease;
}

.apart-header:hover {
  color: #28a745; /* Hover to green */
}

/* Style the unordered list */
.apart-list {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0 auto;
  max-width: 800px;
}

/* Style for section headings */
.apart-heading {
  color: red; /* Bright blue for headings */
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Style for the paragraphs */
.apart-list p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Ensure good layout for the container */
.apart-container {
  padding: 30px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  background-color: #f8f9fa; /* Light gray background */
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Add hover effect for list items */
.apart-list li {
  padding: 15px 20px;
  border-radius: 10px;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.apart-list li:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  background-color: #e9ecef; /* Light hover effect */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .apart-header {
    font-size: 2rem;
  }
  .apart-heading {
    font-size: 1.2rem;
  }
  .apart-list p {
    font-size: 1rem;
  }
}


/* AppointmentSection.css */
.appointmentf {
  background-image: url('../images/FC6.jpeg'); /* Your background image URL */
  height: 50%;
  width: 100%;
  color: white; /* Text color to stand out on background */
  text-align: center;
  background-size: cover;
  background-position: center center;
}

.containerf {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2px 0 4px 0;
}


.factf {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for better text contrast */
  border-radius: 12px;
  display: inline-block;
  max-width: 650px;
  width: 100%;
  margin-top: 20px;
}

.fact-headingf {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: #f4f4f4;
}

.fact-descriptionf {
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 40px;
  color: #ddd;
}

/* Button Styles */
.btn-appointmentf {
  display: inline-block;
  padding: 12px 35px;
  background-color: #bf3f08; /* Green button color */
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.btn-appointmentf:hover {
  background-color: #0cf23e; /* Darker green color on hover */
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25); /* Deepen shadow on hover */
}

.btn-appointmentf:focus {
  outline: none;
}

/* Added click effect */
.btn-appointmentf:active {
  background-color: #1e7e34; /* Darker green on click */
  transform: translateY(2px); /* Button pressed-down effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow on click */
}
/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
}


/* Carousel container */
.carousl-container {
  position: relative;
  width: 100%;
  height: 500px; /* Adjusted height for the carousel */
  overflow: hidden;
}

/* Carousel slide */
.carousel-slide1 {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  animation: fadeEffect 10s ease-in-out infinite, zoomEffect 10s ease-in-out infinite;
}

/* Zoom effect */
@keyframes zoomEffect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Fade in-out effect */
@keyframes fadeEffect {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Text overlay container */
.txt-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 40px;
  border-radius: 10px;
  text-align: center;
}

/* Carousel text */
.carousel-txt {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  animation: fadeTextEffect 10s ease-in-out infinite;
}

/* Text fade effect */
@keyframes fadeTextEffect {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
/* Mission Section Styling */
.vision1-section {
  background-color: #f7f7f7;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.containerm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  flex-direction: row-reverse; /* Reverses the order of image and content */
}

.vision1-content {
  width: 60%;
  max-width: 800px;
  margin-left: 20px; /* Adjust space between image and content */
}

.vision1-heading {
  font-size: 36px;
  font-weight: bold;
  color: #2b3d47;
  margin-bottom: 20px;
}

.vision1-description {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 40px;
}

.vision1-image {
  width: 40%;
  max-width: 500px;
}

.vision1-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.vision1-image img:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .containerm {
    flex-direction: column;
    text-align: center;
  }

  .vision1-content {
    width: 100%;
    margin-bottom: 20px;
    padding-top: 5px;
  }

  .vision1-heading {
    font-size: 20px;
    padding-top: 5px;
  }

  .vision1-description {
    font-size: 16px;
  }

  .vision1-image {
    width: 100%;
    margin-top: 20px;
  }
}

.readmore1 {
  color: red;
  font-weight: 600;
}
.readmore1:hover {
  color: green;
}

/* Section Styling */
.about-it-staffing{
  padding: 2rem 1rem;
  background-color: #f9f9f9;
  color: #333;
}

.section-title {
  font-size: 2rem;
  color: #007BFF;
  text-align: center;
  margin-bottom: 1rem;
}

.divider1 {
  width: 50px;
  height: 3px;
  background-color: red;
  margin: 0 auto 2rem auto;
  border: none;
}

/* Flexbox Layout for Content */
.contxt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: nowrap;
}

/* Text Styling */
.textt {
  flex: 1;
  max-width: 60%;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

/* Image Container */
.pic-container {
  position: relative;
  flex: 1;
  max-width: 40%;
}

/* Animated Image Styling */
.animated-pic {
  width: 100%;
  height: auto;
  animation: shapeChange 5s infinite alternate;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Keyframes for Shape Animation */
@keyframes shapeChange {
  0% {
    clip-path: circle(50%);
  }
  25% {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  }
  50% {
    clip-path: polygon(50% 0%, 75% 25%, 100% 50%, 75% 75%, 50% 100%, 25% 75%, 0% 50%, 25% 25%);
  }
  75% {
    clip-path: polygon(50% 0%, 85% 20%, 100% 50%, 85% 80%, 50% 100%, 15% 80%, 0% 50%, 15% 20%);
  }
  100% {
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .contxt {
    flex-direction: column;
    text-align: center;
  }

  .textt {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .pic-container {
    max-width: 100%;
  }
}

/* Section Styling */
.sect {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f2f2f2;
}

.sect-title {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  color: #2b3d47;
  margin-bottom: 20px;
  line-height: 1.4;
  transition: transform 0.3s ease;
}

.sect-title:hover {
  transform: scale(1.1);
}

.underline {
  width: 150px;
  height: 4px;
  background-color: #e74c3c;
  margin: 10px auto;
}

.sect-subtitle {
  font-size: 16px;
  color: #555;
  text-align: center;
}

.readmore {
  color: #e74c3c;
  font-weight: 600;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.readmore:hover {
  color: #c0392b;
}

@media screen and (max-width: 768px) {
  .sect {
    padding: 20px;
  }

  .sect-title {
    font-size: 24px;
  }

  .underline {
    width: 100px;
  }
}

/* Card Styling */
.card {
  border: 1px solid #ddd;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.card-text {
  font-size: 14px;
  color: #666;
}

.card-img-top {
  max-height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card:hover .card-img-top {
  transform: scale(1.05);
}

.sect1-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.underlinen {
  width: 150px;
  height: 4px;
  background-color: #e74c3c;
  margin: 0 auto 20px;
}

.feature-box {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  padding: 25px;
  text-align: center;
}

.feature-box:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.feature-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.feature-text {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.fas {
  color: #e74c3c;
  transition: color 0.3s;
}

.feature-box:hover .fas {
  color: #c0392b;
}


/* AppointmentSection.css */
.appointmentst {
  background-image: url('../images/ITCS6.jpg'); /* Your background image URL */
  height: 50%;
  width: 100%;
  color: white; /* Text color to stand out on background */
  text-align: center;
  background-size: cover;
  background-position: center center;
  
}
/* General Form Styles */
h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.offset-md-0 {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px 40px 40px 40px;
  margin: 40px 40px 40px 40px;
}

.alert {
  margin-bottom: 20px;
}

.Btns {
  font-size: 1.1rem;
  padding: 20px 75px;
  border-radius: 75px;
  color: black;
  background-color:lightcyan;
}

/* Align label and input on one line */
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group label {
  flex: 1; /* Label occupies 1 portion of the space */
  margin-right: 10px; /* Space between label and input */
  font-weight: bold;
  text-align: right; /* Align labels to the right */
}

.form-group .form-control {
  flex: 2; /* Input occupies 2 portions of the space */
  border-radius: 0.375rem;
}

/* Ensure textareas align properly */
textarea.form-control {
  flex: 2;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
    align-items: stretch;
  }

  .form-group label {
    text-align: left; /* Labels align to the left in stacked layout */
    margin-bottom: 5px; /* Add spacing for stacked label */
  }
}

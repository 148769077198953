/* Base styles for the section */
.contact-section {
  font-family: 'Open Sans', sans-serif;
  color: #333;
  line-height: 1.6;
}

/* Background styles for the Contact Header */
.contact-bg {
  height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../images/contact.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.contact-bg h3 {
  font-size: 1.3rem;
  font-weight: 400;
}

.contact-bg h2 {
  font-size: 3rem;
  text-transform: uppercase;
  padding: 0.4rem 0;
  letter-spacing: 4px;
}

.contact-bg .text {
  font-weight: 300;
  opacity: 0.9;
  margin: 1.6rem 0;
}

.line {
  display: flex;
  align-items: center;
}

.line div {
  margin: 0 0.2rem;
}

.line div:nth-child(1),
.line div:nth-child(3) {
  height: 3px;
  width: 70px;
  background: #f7327a;
  border-radius: 5px;
}

.line div:nth-child(2) {
  width: 10px;
  height: 10px;
  background: #f7327a;
  border-radius: 50%;
}

/* Contact Body */
.contact-body {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 1rem;
}

.contact-info {
  margin: 3rem 0;
  text-align: center;
  padding: 2rem 0;
}

.contact-info div {
  margin: 0.8rem 0;
  padding: 1rem;
}

.contact-info span {
  display: block;
}

.contact-info span .fas {
  font-size: 2rem;
  padding-bottom: 0.9rem;
  color: #f7327a;
}

.contact-info div span:nth-child(2) {
  font-weight: 500;
  font-size: 1.1rem;
}

.contact-info .text {
  padding-top: 0.4rem;
  text-align: center;
  transform: translate(45px, 2px);
}

/* Contact Form */
.contact-form {
  padding: 2rem 0;
  border-top: 1px solid #c7c7c7;
}

.contact-form form {
  padding-bottom: 1rem;
}

.form-control {
  width: 100%;
  border: 1.5px solid #c7c7c7;
  border-radius: 5px;
  padding: 0.7rem;
  margin: 0.6rem 0;
  font-size: 1rem;
  outline: 0;
}

.form-control:focus {
  box-shadow: 0 0 6px -3px rgba(48, 48, 48, 1);
}

.contact-form form div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.6rem;
}

.send-btn {
  font-size: 1rem;
  text-transform: uppercase;
  color: #fff;
  background: #f7327a;
  border: none;
  border-radius: 5px;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
  transition: all 0.4s ease;
}

.send-btn:hover {
  opacity: 0.8;
}

.contact-form > div img {
  width: 85%;
  display: block;
  margin: 0 auto;
}

/* Contact Footer */
.contact-footer {
  padding: 2rem 0;
  background: #000;
  color: #fff;
  text-align: center;
}

.contact-footer h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  justify-content: center;
}

.social-links a {
  text-decoration: none;
  width: 40px;
  height: 40px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4rem;
  transition: all 0.4s ease;
}

.social-links a:hover {
  color: #f7327a;
  border-color: #f7327a;
}

/* Map Styles */
.map iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

/* Responsive Adjustments */
@media screen and (min-width: 768px) {
  .contact-bg .text {
      width: 70%;
      margin: auto;
  }

  .contact-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .contact-bg .text {
      width: 50%;
  }

  .contact-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .contact-info {
      grid-template-columns: repeat(4, 1fr);
  }
}

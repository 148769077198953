.footer {
    background-color: #000; /* Black background */
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .footer-logo {
    width: 100px;
    margin-right: 20px;
  }
  
  .footer-services,
  .footer-contact {
    flex: 1;
    text-align: left;
  }

  .footer-services{
    padding-left: 300px;
  }
  
  .footer-services h5,
  .footer-contact h5 {
    font-weight: bold;
  }
  
  .footer p,
  .footer a {
    color: #ccc;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer a:hover {
    color: #f2f2f2;
  }
  
  .footer-social-media {
    text-align: center;
    margin-top: 20px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-icon {
    font-size: 24px;
    color: #ccc;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #ff5733;
  }
  
  /* Contact Icons */
  .contact-icon {
    color: #ff5733;
    margin-right: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer .row {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-services,
    .footer-contact {
      margin-bottom: 20px;
    }
  }
  